import React from 'react';
import img1 from "../assets/slider-img.png"

const Hero = () => {
  return (
    <div className="flex flex-col   text-white md:flex-row items-center justify-between pt-[4%]  ">
      <div className='container flex mm:flex-col lg:flex-row px-[13%]'>
          <div className="md:w-1/2 text-center flex flex-col justify-center md:text-left">
            <div className="mm:text-3xl lg:text-5xl font-bold mb-4 uppercase">ASSESSORIA CONTÁBIL <br /> REAL BRASIL
            </div>
            
            <a href="https://www.instagram.com/escritorio_realbrasil/" target='_blank' rel='noreferrer'>
              <button className="bg-botao lg:w-[50%] text-white text-lg font-medium py-2 px-4 rounded transition-all duration-300 ease-in-out hover:bg-[#11968f]">
                Entre em contato conosco
              </button>
            </a>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0 flex justify-end">
            <img src={img1} alt="" className="lg:w-[80%] mm:w-[100%] h-auto rounded animate-bounce" />
          </div>
      </div>
    </div>
  );
};

export default Hero;
