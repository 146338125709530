import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-back bg-[#07003A]">
      <div className="text-center">
        <h1 className="text-9xl font-bold text-white">404</h1>
        <p className="text-2xl font-semibold text-white mb-8">
          Oops! Página não encontrada.
        </p>
        <Link to="/" className="text-lg text-blue-500 hover:underline">
          Voltar para a página inicial
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
